import { Row, Col, Form, Button, Table, Card, Modal, Input, Select, message } from 'antd'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
const { Option } = Select;

const token = localStorage.getItem("accessToken")

const headers = {
    Authorization: `Bearer ${token}`
}

const columnas = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: '10%'
    },
    {
        title: 'Prefijo',
        dataIndex: 'prefijo',
        key: 'prefijo',
        width: '25%'
    },
    {
        title: 'Sufijo',
        dataIndex: 'sufijo',
        key: 'sufijo',
        width: '25%'
    },
    {
        title: 'Prefijo Pais',
        dataIndex: 'pais_prefijo',
        key: 'pais_prefijo',
        width: '10%',
        render: text => text == 0 ? 'No' : 'Sí'
    },
    {
        title: 'Sufijo Pais',
        dataIndex: 'pais_sufijo',
        key: 'pais_sufijo',
        width: '10%',
        render: text => text == 0 ? 'No' : 'Sí'
    },
    {
        title: 'Número actual',
        dataIndex: 'numero_actual',
        key: 'numero_actual',
        width: '15%'
    },
    {
        title: 'Acciones',
        dataIndex: 'acciones',
        key: 'acciones',
        width: '5%'
    },
];

const columnasPaises = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: '10%'
    },
    {
        title: 'Pais codigo',
        dataIndex: ["pais", "codigo"],
        key: 'pais_codigo',
        width: '15%'
    },
    {
        title: 'Nombre',
        dataIndex: ["pais", "nombre"],
        key: 'pais_nombre',
        width: '40%'
    },
    {
        title: 'Última factura',
        dataIndex: 'maximo_existente',
        key: 'maximo_existente',
        width: '15%'
    },
    {
        title: 'Número actual',
        dataIndex: 'numero_actual',
        key: 'numero_actual',
        width: '10%'
    },
    {
        title: 'Actualizar',
        dataIndex: 'actualizar',
        key: 'actualizar',
        width: '10%'
    },
];

const ref_crear = React.createRef();
const ref_actualizar = React.createRef();

export const Contadores = () => {

    const [actualizar, setActualizar] = useState([]);
    const [contadoresPaises, setContadoresPaises] = useState([]);
    const [contadores, setContadores] = useState([]);
    const [contador, setContador] = useState([]);
    const [modalCrear, setModalCrear] = useState(false);
    const [modalActualizar, setModalActualizar] = useState(0);

    const handleKeyUp = (contador_pais_id) => async (event) => {
        if (event.keyCode === 13) {
            await axios.patch(`${process.env.REACT_APP_RUTA_API}/contadores-paises/${contador_pais_id}`, { numero_actual: event.target.value }, { headers });
            actualizadorContadoresPaises();
        }
    }

    const actualizador = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/contadores?$limit=-1`, { headers });
        setActualizar(data);
    }

    const actualizadorContadoresPaises = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/contadores-paises?contador_id=${modalActualizar}&$limit=-1`, { headers });
        for (const cp of data) {
            cp.actualizar = (
                <Form initialValues={{ numero_actual: cp.numero_actual }} onKeyUp={handleKeyUp(cp.id)}>
                    <Form.Item name="numero_actual" label="">
                        <Input />
                    </Form.Item>
                </Form>
            )
        }
        setContadoresPaises(data);
    };
    useEffect(() => {
        actualizador()
    }, []);

    useEffect(() => {
        actualizadorContadoresPaises();
    }, [modalActualizar]);

    useEffect(() => {
        let isMounted = true;

        const cargarContadores = [];
        if (actualizar && actualizar.length) {
            for (const item of actualizar) {
                item.key = item.id;
                item.acciones = (
                    <Col className="text-right">
                        <Button onClick={() => {
                            setContador(item)
                            setModalActualizar(item.id)
                        }} className="mr-2 bg-warning border-0" type="primary" icon={<EditOutlined />} size={'small'} />
                        <Button onClick={() => borrarContador(item.id)} className="bg-danger border-0" type="primary" icon={<DeleteOutlined />} size={'small'} />
                    </Col>
                )
                cargarContadores.push(item)
            }
            if (isMounted) setContadores(cargarContadores);
        }
        return () => { isMounted = false }
    }, [actualizar]);

    /* useEffect(() => {
        let isMounted = true;

        const llamada = async () => {
            const cargarContadores = [];
            const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/contadores?$limit=-1`, { headers });
            if (data && data.length) {
                for (const item of data) {
                    item.key = item.id;
                    item.acciones = (
                        <Col className="text-right">
                            <Button onClick={() => {
                                setContador(item)
                                setModalActualizar(item.id)
                            }} className="mr-2 bg-warning border-0" type="primary" icon={<EditOutlined />} size={'small'} />
                            <Button onClick={() => borrarContador(item.id)} className="bg-danger border-0" type="primary" icon={<DeleteOutlined />} size={'small'} />
                        </Col>
                    )
                    cargarContadores.push(item)
                }
                if (isMounted) setContadores(cargarContadores);
            }
        };
        llamada();
        return () => { isMounted = false }
    }, []); */

    useEffect(() => {
        ref_actualizar.current?.setFieldsValue(contador);
    }, [contador]);

    const abrirModal = () => {
        setModalCrear(true)
    }

    const cerrarModalCrear = () => {
        setModalCrear(false);
        ref_crear.current?.resetFields();
        actualizador();
    }

    const cerrarModalActualizar = () => {
        setModalActualizar(false);
        ref_actualizar.current?.resetFields();
    }

    const crearContador = async (datos) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_RUTA_API}/contadores`, { ...datos }, { headers })
            if (data) {
                ref_crear.current?.resetFields();
                setModalCrear(false);
                message.success(`El contador se ha creado con éxito`)
            }
        } catch (e) {
            message.error(`Error al crear el contador`)
        }
    }

    const borrarContador = async (contador) => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_RUTA_API}/contadores/${contador}`, { headers })
            console.log(data)
            if (data) {
                message.success(`El contador se ha borrado con éxito`)
            }
        } catch (e) {
            message.error(`Error al borrar el contador`)
        }
    }

    const actualizarContador = async (contador) => {
        try {
            const { data } = await axios.patch(`${process.env.REACT_APP_RUTA_API}/contadores/${modalActualizar}`, { ...contador }, { headers })
            if (data) {
                ref_actualizar.current?.resetFields();
                setModalActualizar(false);
                message.success(`El contador se ha actualizado con éxito`)
            }
        } catch (e) {
            message.error(`Error al actualizar el contador`)
        }
    }

    return (
        <>
            <Row className="px-5 pt-5" >
                <Col span={24}>
                    <Card
                        title="Gestionar Contadores"
                        extra={<Button type="primary" htmlType="submit" onClick={abrirModal}>
                            Crear Contador
                        </Button>}
                    >
                        <Table className="contabilidad" columns={columnas} size="small" pagination={false} dataSource={contadores} />
                    </Card>
                </Col>
            </Row>
            <Modal title="Crear Contador" visible={modalCrear} onCancel={cerrarModalCrear} footer={null}>
                <Form initialValues={{ numero_actual: 1 }} name="crear" ref={ref_crear} onFinish={crearContador}>
                    <Form.Item name="prefijo" label="Prefijo">
                        <Input />
                    </Form.Item>
                    <Form.Item name="pais_prefijo" label="Prefijo Pais" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Select
                            placeholder="Usar el pais como prefijo..."
                            allowClear
                        >
                            <Option value="0">No</Option>
                            <Option value="1">Sí</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="pais_sufijo" label="Sufijo Pais" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Select
                            placeholder="Usar el pais como sufijo..."
                            allowClear
                        >
                            <Option value="0">No</Option>
                            <Option value="1">Sí</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="sufijo" label="Sufijo">
                        <Input />
                    </Form.Item>
                    <Form.Item name="numero_actual" label="Numeración inicial" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 20, span: 24 }} >
                        <Button type="primary" htmlType="submit">
                            Crear
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="Actualizar Contador" visible={modalActualizar > 0} onCancel={cerrarModalActualizar} footer={null}>
                <Form initialValues={contador} name="actualizar" ref={ref_actualizar} onFinish={actualizarContador}>
                    <Form.Item name="prefijo" label="Prefijo">
                        <Input />
                    </Form.Item>
                    <Form.Item name="pais_prefijo" label="Prefijo Pais" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Select
                            placeholder="Usar el pais como prefijo..."
                            allowClear
                        >
                            <Option value="0">No</Option>
                            <Option value="1">Sí</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="pais_sufijo" label="Sufijo Pais" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Select
                            placeholder="Usar el pais como sufijo..."
                            allowClear
                        >
                            <Option value="0">No</Option>
                            <Option value="1">Sí</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="sufijo" label="Sufijo">
                        <Input />
                    </Form.Item>
                    <Form.Item name="numero_actual" label="Numeración actual" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 20, span: 24 }} >
                        <Button type="primary" htmlType="submit">
                            Actualizar
                        </Button>
                    </Form.Item>
                </Form>
                {contador.pais_prefijo || contador.pais_sufijo ?
                    < Table className="contabilidad" columns={columnasPaises} size="small" pagination={false} dataSource={contadoresPaises} /> : ''
                }
            </Modal>
        </>

    )

}
