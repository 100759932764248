import { Row, Col, Form, Button, Table, Card, Modal, Input, message } from 'antd'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const token = localStorage.getItem("accessToken")

const headers = {
    Authorization: `Bearer ${token}`
}

const columnas = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: '10%'
    },
    {
        title: 'Código',
        dataIndex: 'codigo',
        key: 'codigo',
        width: '35%'
    },
    {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre',
        width: '50%'
    },
    {
        title: 'Acciones',
        dataIndex: 'acciones',
        key: 'acciones',
        width: '5%'
    },
];

const ref_crear = React.createRef();
const ref_actualizar = React.createRef();

export const Paises = () => {

    const [paises, setPaises] = useState([]);
    const [pais, setPais] = useState([]);
    const [modalCrear, setModalCrear] = useState(false);
    const [modalActualizar, setModalActualizar] = useState(0);

    useEffect(() => {
        let isMounted = true;

        const llamada = async () => {
            const cargarPaises = [];
            const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/paises?$limit=-1`, { headers });
            if (data && data.length) {
                for (const item of data) {
                    item.key = item.id;
                    item.acciones = (
                        <Col className="text-right">
                            <Button onClick={() => {
                                setPais(item);
                                setModalActualizar(item.id);

                            }} className="mr-2 bg-warning border-0" type="primary" icon={<EditOutlined />} size={'small'} />
                            <Button onClick={() => borrarPais(item.id)} className="bg-danger border-0" type="primary" icon={<DeleteOutlined />} size={'small'} />
                        </Col>
                    )
                    cargarPaises.push(item)
                }
                if (isMounted) setPaises(cargarPaises);
            }
        };
        llamada();
        return () => { isMounted = false }
    }, []);

    useEffect(() => {
        ref_actualizar.current?.setFieldsValue(pais);
    }, [pais]);

    const abrirModal = () => {
        setModalCrear(true)
        //ref_actualizar.current?.resetFields();
    }

    const cerrarModalCrear = () => {
        setModalCrear(false);
        ref_crear.current?.resetFields();
    }

    const cerrarModalActualizar = () => {
        setModalActualizar(false);
        ref_actualizar.current?.resetFields();
    }

    const crearPais = async (datos) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_RUTA_API}/paises`, { ...datos }, { headers })
            if (data) {
                ref_crear.current?.resetFields();
                setModalCrear(false);
                message.success(`El pais ${data.nombre} se ha creado con éxito`)
            }
        } catch (e) {
            message.error(`Error al crear el usuario`)
        }
    }

    const borrarPais = async (pais) => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_RUTA_API}/paises/${pais}`, { headers })
            console.log(data)
            if (data) {
                message.success(`El pais ${data.nombre} se ha borrado con éxito`)
            }
        } catch (e) {
            message.error(`Error al borrar el pais`)
        }
    }

    const actualizarPais = async (pais) => {
        try {
            const { data } = await axios.patch(`${process.env.REACT_APP_RUTA_API}/paises/${modalActualizar}`, { ...pais }, { headers })
            if (data) {
                setModalActualizar(false);
                message.success(`El pais ${data.nombre} se ha actualizado con éxito`)
            }
        } catch (e) {
            message.error(`Error al actualizar el pais`)
        }
    }

    return (
        <>
            <Row className="px-5 pt-5" >
                <Col span={24}>
                    <Card
                        title="Gestionar Paises"
                        extra={<Button type="primary" htmlType="submit" onClick={abrirModal}>
                            Crear Pais
                        </Button>}
                    >
                        <Table className="contabilidad" columns={columnas} size="small" pagination={false} dataSource={paises} />
                    </Card>
                </Col>
            </Row>
            <Modal title="Crear Pais" visible={modalCrear} onCancel={cerrarModalCrear} footer={null}>
                <Form name="crear" ref={ref_crear} onFinish={crearPais}>
                    <Form.Item name="codigo" label="Código" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="nombre" label="Nombre" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 20, span: 24 }} >
                        <Button type="primary" htmlType="submit">
                            Crear
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="Actualizar Pais" visible={modalActualizar > 0} onCancel={cerrarModalActualizar} footer={null}>{modalActualizar}{pais.nombre}
                <Form initialValues={pais} name="actualizar" ref={ref_actualizar} onFinish={actualizarPais}>
                    <Form.Item name="codigo" label="Código" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="nombre" label="Nombre" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 20, span: 24 }} >
                        <Button type="primary" htmlType="submit">
                            Actualizar
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )

}
