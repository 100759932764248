import { Row, Col, Button, Table, Card, message, Popconfirm, Form, Input, InputNumber, Select, Modal, Grid } from 'antd'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const token = localStorage.getItem("accessToken")

const headers = {
    Authorization: `Bearer ${token}`
}

const columnas = [
    {
        title: 'Nombre propio',
        dataIndex: 'nombre_propio',
        key: 'nombre_propio',
        width: '30%'
    },
    {
        title: 'Vinculado',
        dataIndex: 'vinculado',
        key: 'vinculado',
        width: '30%'
    },
    {
        title: 'Acciones',
        dataIndex: 'acciones',
        key: 'acciones',
        width: '10%'
    },
];

const ref_crear = React.createRef();
const ref_actualizar = React.createRef();

export const Gesproductos = () => {

    const [actualizar, setActualizar] = useState([]);
    const [productosCF, setProductosCF] = useState([]);
    const [productos, setProductos] = useState([]);
    const [producto, setProducto] = useState([]);
    const [relacionados, setRelacionados] = useState([]);
    const [modalCrear, setModalCrear] = useState(false);
    const [modalActualizar, setModalActualizar] = useState(0);

    const actualizador = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/productos?$sort[nombre_propio]=-1&$limit=-1`, { headers });
        setActualizar(data);
    }

    const actualizadorCF = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/productos?$cf=1&$limit=-1`, { headers });
        setProductosCF(data);
    }

    useEffect(() => {
        actualizador();
        actualizadorCF();
    }, []);

    useEffect(() => {
        setRelacionados(producto.productos_cf ?? []);
    }, [producto]);

    useEffect(() => {
        ref_actualizar.current?.setFieldsValue(producto);
    }, [producto]);

    useEffect(() => {
        let isMounted = true;

        const cargarProductos = [];
        if (actualizar && actualizar.length) {
            for (const item of actualizar) {
                item.key = item.id;
                item.vinculado = (
                    <Col className="text-left">
                        {item.productos_cf.length ? 'Sí' : 'No'}
                    </Col>
                );
                item.acciones = (
                    <Col className="text-right">
                        <Button onClick={() => {
                            setProducto(item)
                            setModalActualizar(item.id)
                        }} className="mr-2 bg-warning border-0" type="primary" icon={<EditOutlined />} size={'small'} />
                        <Popconfirm placement="topLeft" title={'Esta operación no puede deshacerse, ¿quieres continuar?'} onConfirm={() => borrarProducto(item.id)} okText="Aceptar" cancelText="Cancelar" icon="" className="bg-danger border-0">
                            <Button className="bg-danger border-0" type="primary" icon={<DeleteOutlined />} size={'small'} />
                        </Popconfirm>

                    </Col>
                )
                cargarProductos.push(item)
            }
            if (isMounted) setProductos(cargarProductos);
        }
        return () => { isMounted = false }
    }, [actualizar]);

    const abrirModal = () => {
        setModalCrear(true);
        setRelacionados([]);
    }

    const cerrarModalCrear = () => {
        setModalCrear(false);
        ref_crear.current?.resetFields();
        actualizador();
    }

    const cerrarModalActualizar = () => {
        setModalActualizar(false);
        ref_actualizar.current?.resetFields();
        actualizador();
    }

    const borrarProducto = async (producto) => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_RUTA_API}/productos/${producto}`, { headers })
            actualizador();
            if (data) {
                message.success(`El producto se ha borrado con éxito`)
            }
        } catch (e) {
            message.error(`Error al borrar el producto`)
        }
    }

    const crearProducto = async (datos) => {
        const producto = {
            ...datos,
            productos_cf: relacionados
        };
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_RUTA_API}/productos`, producto, { headers })
            if (data) {
                ref_crear.current?.resetFields();
                setModalCrear(false);
                actualizador();
                message.success(`El producto se ha creado con éxito`)
            }
        } catch (e) {
            message.error(`Error al crear el producto`)
        }
    }

    const actualizarProducto = async (datos) => {
        const producto = {
            ...datos,
            productos_cf: relacionados
        };
        try {
            const { data } = await axios.patch(`${process.env.REACT_APP_RUTA_API}/productos/${modalActualizar}`, producto, { headers })
            if (data) {
                ref_actualizar.current?.resetFields();
                setModalActualizar(false);
                actualizador();
                message.success(`El producto se ha actualizado con éxito`)
            }
        } catch (e) {
            message.error(`Error al actualizar el producto`)
        }
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const moduloCF = (
        <>
            <h5>
                Productos CF
            </h5>
            {relacionados.map((item, index) => (
                <>
                    <Row>
                        <Col flex="auto">
                            <Form.Item label="Producto CF" rules={[{ required: true, message: 'Campo Requerido' }]} >
                                <Select
                                    showSearch
                                    placeholder="Selecciona un nombre de producto de CF"
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    options={productosCF}
                                    value={item.nombre_cf}
                                    onChange={(value) => {
                                        const newRelacionados = [...relacionados];
                                        newRelacionados[index].nombre_cf = value;
                                        setRelacionados(newRelacionados);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col flex="200px">
                            <Form.Item label="Formato (Kg)" rules={[{ required: true, message: 'Campo Requerido' }]} >
                                <InputNumber
                                    value={item.formato_kg}
                                    parser={value => value.replace(new RegExp(/,/g), '.')}
                                    onChange={(value) => {
                                        const newRelacionados = [...relacionados];
                                        newRelacionados[index].formato_kg = value;
                                        setRelacionados(newRelacionados);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col flex="50px">
                            <Button
                                className="bg-danger border-0"
                                type="primary"
                                icon={<DeleteOutlined />}
                                size={'small'}
                                htmlType="button"
                                onClick={() => {
                                    const newRelacionados = [...relacionados];
                                    newRelacionados.splice(index, 1);
                                    setRelacionados(newRelacionados);
                                }} />
                        </Col>
                    </Row>
                </>
            ))}
            <Button onClick={() => {
                const newRelacionados = [...relacionados, { nombre_cf: '', formato_kg: '' }];
                setRelacionados(newRelacionados);
            }} type="primary" htmlType="button">
                Añadir Producto CF
            </Button>
        </>
    )

    return (
        <>
            <Row className="px-5 pt-5" >
                <Col span={24}>
                    <Card
                        title="Gestionar Productos"
                        extra={<Button type="primary" htmlType="submit" onClick={abrirModal}>
                            Crear Producto
                        </Button>}
                    >
                        <Table className="productos" columns={columnas} size="small" pagination={false} dataSource={productos} />
                    </Card>
                </Col>
            </Row>
            <Modal title="Crear Producto" open={modalCrear} onCancel={cerrarModalCrear} footer={null} width={'800px'}>
                <Form name="crear" ref={ref_crear} onFinish={crearProducto}>
                    <Form.Item name="nombre_propio" label="Nombre propio" rules={[{ required: true, message: 'Campo Requerido' }]} >
                        <Input />
                    </Form.Item>
                    {moduloCF}
                    <Form.Item wrapperCol={{ offset: 20, span: 24 }} >
                        <Button type="primary" htmlType="submit">
                            Crear
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="Actualizar Producto" open={modalActualizar > 0} onCancel={cerrarModalActualizar} footer={null} width={'800px'}>
                <Form initialValues={producto} name="actualizar" ref={ref_actualizar} onFinish={actualizarProducto}>
                    <Form.Item name="nombre_propio" label="Nombre propio" rules={[{ required: true, message: 'Campo Requerido' }]} >
                        <Input />
                    </Form.Item>
                    {moduloCF}
                    <Form.Item wrapperCol={{ offset: 20, span: 24 }} >
                        <Button type="primary" htmlType="submit">
                            Actualizar
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )

}
