import React, { useState } from 'react';
import axios from 'axios';

import './login.less';
import { Form, Input, Button, Layout, Row, Col, message, Modal } from 'antd';
import { ContainerOutlined } from '@ant-design/icons';

const { Content } = Layout;

export const Login = () => {
    const [abrirModal, setAbrirModal] = useState(true);

    message.config({
        duration: 2
    });

    const onFinish = async ({ email, password }) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_RUTA_API}/authentication`, { email, password, strategy: 'local' });
            if (data && data.accessToken) {
                localStorage.setItem("accessToken", data.accessToken);
                window.location.href = "/lotes";
            }
        } catch (e) {
            message.error('Usuario o Contraseña incorrecto')
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const contenidoModal = (
        <div>
            <p>La siguiente declaración de exención de responsabilidad tiene como objetivo informar a los usuarios de la aplicación en fase beta sobre los posibles errores y la protección de datos de acuerdo con la legislación vigente de protección de datos en España:</p>
            <ol>
                <li>Errores y Limitaciones: La aplicación se encuentra en fase beta, lo que implica que aún está en desarrollo y puede contener errores o limitaciones en su funcionamiento. Los usuarios comprenden y aceptan que el uso de la aplicación se realiza bajo su propio riesgo. No nos hacemos responsables de cualquier pérdida de datos, daños o perjuicios causados directa o indirectamente por el uso de la aplicación en esta fase de prueba.</li>
                <li>Confidencialidad de los Datos: Valoramos y respetamos la privacidad de los usuarios. Todos los datos ingresados en la aplicación serán tratados de acuerdo con la legislación española vigente sobre protección de datos, especialmente en cumplimiento con la Ley Orgánica de Protección de Datos Personales y Garantía de los Derechos Digitales (LOPDGDD) y el Reglamento General de Protección de Datos (RGPD). Sin embargo, durante la fase beta, no podemos garantizar la seguridad completa de los datos debido a posibles vulnerabilidades y riesgos asociados con el desarrollo de la aplicación.</li>
                <li>Uso de Datos Ficticios: Recomendamos encarecidamente a los usuarios que eviten utilizar datos reales o sensibles en la aplicación durante la fase beta. Dado que la aplicación aún está en desarrollo, pueden producirse errores o problemas técnicos que podrían comprometer la confidencialidad o integridad de los datos ingresados. Se recomienda encarecidamente utilizar datos ficticios o de prueba para evitar cualquier posible riesgo o exposición de información confidencial.</li>
                <li>Responsabilidad del Usuario: Los usuarios son responsables de asegurarse de que tienen los derechos y permisos necesarios para cargar y utilizar los datos en la aplicación. También se espera que los usuarios mantengan copias de seguridad actualizadas de sus datos, ya que no asumimos ninguna responsabilidad por la pérdida o corrupción de datos almacenados en la aplicación.</li>
                <li>Cambios y Actualizaciones: Nos reservamos el derecho de realizar cambios, actualizaciones o mejoras en la aplicación durante la fase beta, lo que puede afectar la funcionalidad y el tratamiento de los datos. Los usuarios serán informados sobre estos cambios en la medida de lo posible.</li>
            </ol>
            <p>Al utilizar la aplicación en fase beta, los usuarios aceptan y reconocen los términos y condiciones expuestos en este descargo de responsabilidad. Les recomendamos que revisen periódicamente esta declaración, ya que puede estar sujeta a cambios.</p>
            <p>Recuerden que esta declaración de exención de responsabilidad no reemplaza ni modifica cualquier acuerdo legal o términos de uso que puedan establecerse adicionalmente entre los usuarios y la entidad responsable de la aplicación.</p>
        </div>
    );
    /*
        const modal = Modal.warning(
            {
                title: 'Descargo de Responsabilidad de Datos para la Aplicación en Fase Beta',
                content: contenidoModal
            }
        );
        */
    return (
        <Content >
            <div className="contenido">
                <Row justify="start" >
                    <Col>
                        <div className="my-2 d-flex justify-content-end align-items-center">
                            <ContainerOutlined className="mr-2 icono" />
                            <h1>
                                factuLotes
                                <small className="ml-2 text-muted">BETA</small>
                            </h1>
                        </div>
                        <div className="form-container">
                            <Form
                                name="basic"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ required: true, message: '¡Introduzca su email!' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Contraseña"
                                    name="password"
                                    rules={[{ required: true, message: '¡Introduzca su contraseña!' }]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 16, span: 16 }}>
                                    <Button type="primary" htmlType="submit" >
                                        ENTRAR
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal
                title="Descargo de Responsabilidad de Datos para la Aplicación en Fase Beta"
                visible={abrirModal} footer={
                    <Button type="primary" onClick={() => setAbrirModal(false)}>Aceptar</Button>
                }
                closable={false}
                width="xl">
                {contenidoModal}
            </Modal>
        </Content>
    );
}

