import {
    Result,
    Layout,
    Col
} from 'antd';

export const Pagina404 = () => {
    return (
        <Layout>
            <Col span={12} offset={6} className="py-3">
                <Result
                    status="404"
                    title="404"
                    subTitle="La página que estás intentando visualizar no existe"
                />
            </Col>
        </Layout>

    )
}
