import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import {
    Form,
    Button,
    Card,
    Select,
    Spin,
    Col,
    Radio,
    message
} from 'antd';

const { Option } = Select;

const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`
}

export const Contabilidad = () => {

    const [lote, setLote] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [cuentas, setCuentas] = useState([]);

    useEffect(() => {
        const llamada = async () => {
            const cargarLote = [];
            const lotes = await axios.get(`${process.env.REACT_APP_RUTA_API}/lotes?$limit=-1`, { headers });
            if (lotes.data && lotes.data.length) {
                for (const item of lotes.data) {
                    cargarLote.push(item)
                }
                setLote(cargarLote)
            }
            let datos_cuenta = [];
            const pais = await axios.get(`${process.env.REACT_APP_RUTA_API}/paises?$limit=-1`, { headers });
            if (pais && pais.data.length) {
                for (const item of pais.data) {
                    if (!item.cuenta) {
                        datos_cuenta.push(item.nombre)
                    }
                }
                setCuentas(datos_cuenta);
            }
        };
        llamada();
    }, []);

    const ordenar = (a, b) => {
        if (a.id < b.id) { return 1; }
        if (a.id > b.id) { return -1; }
        return 0;
    }

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    const onFinish = async (campos) => {
        if (cuentas.length) {
            message.error(`Error: Los datos de cuenta para ${cuentas[0]} no se han completado`);
            return;
        }
        setCargando(true)
        const respuesta = await axios.post(`${process.env.REACT_APP_RUTA_API}/contabilidad/`, { ...campos }, { headers });
        if (respuesta && respuesta.data) {
            switch (campos.formato) {
                case 'hispatec':
                    saveAs(new Blob([respuesta.data], { type: "aapplication/xml" }), "descarga.xml");
                    break;
                default:
                    saveAs(new Blob([s2ab(respuesta.data)], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "descarga.xlsx")
                    break;
            }

        } else {
            message.error('Formato de contabilidad no generado')
        }
        setCargando(false)
    };



    return (

        <Spin tip="Descargando..." spinning={cargando}>
            <Col span={16} offset={4} className="pt-5">
                <Card title="Generar Fichero de Contabilidad" >
                    <Form
                        name="generar_contabilidad"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        onFinish={onFinish}
                        initialValues={{ 'formato': 'ares' }}
                    >
                        <Form.Item
                            label="Selecciona un lote"
                            name="lote"
                            rules={[{ required: true, message: '¡Selecciona un lote!' }]}
                            wrapperCol={{ span: 12 }}
                        >
                            <Select placeholder="Selecciona un lote" style={{ width: '200px' }} mode="multiple">
                                {
                                    lote.sort(ordenar).map(item => <Option value={item.id} key={item.id}>{item.nombre}</Option>)
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="formato"
                            label="Elección de formato"
                            rules={[{ required: true, message: 'Elige un formato!' }]}
                        >
                            <Radio.Group>
                                <Radio.Button value="ares">Ares</Radio.Button>
                                <Radio.Button value="hispatec">Hispatec</Radio.Button>
                                <Radio.Button value="a3">A3</Radio.Button>
                                <Radio.Button value="resumen">Resumen</Radio.Button>
                                <Radio.Button value="resumen_simple">Resumen Simple</Radio.Button>
                                <Radio.Button value="gestion">Gestión</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                            <Button type="primary" htmlType="submit">
                                Generar Fichero
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Spin>

    )
}
