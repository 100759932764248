import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode as jwt_decode } from 'jwt-decode';

import {
    Form,
    Button,
    Card,
    message,
    Input,
    Upload,
    Col,
    Table,
    Row,
    Spin
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`
}

let jwt;
let url_usuario;
let url_logo;
if (localStorage.getItem("accessToken")) {
    try {
        jwt = jwt_decode(localStorage.getItem("accessToken"));
        url_usuario = `${process.env.REACT_APP_RUTA_API}/usuarios/${jwt.usuario.id}`;
        url_logo = `${process.env.REACT_APP_RUTA_API}/archivos?carpeta=logo`;
    } catch (error) {
    }
}

const columnas = [
    {
        title: 'Código País',
        dataIndex: 'codigo',
        key: 'codigo',
        width: '15%'
    },
    {
        title: 'País',
        dataIndex: 'nombre',
        key: 'nombre',
        width: '15%'
    },
    {
        title: 'Número Cuenta',
        dataIndex: 'numero',
        key: 'numero',
        width: '35%'
    },
    {
        title: 'Nombre Cuenta',
        dataIndex: 'nombre_cuenta',
        key: 'nombre_cuenta',
        width: '35%'
    },
];


const ref_usuario = React.createRef();
const ref_paises = React.createRef();

export const Configuracion = () => {

    const [usuario, setUsuario] = useState({});
    const [cargando, setCargando] = useState(false);
    const [fileList, setFileList] = useState([]);

    const [cargandoPaises, setCargandoPaises] = useState(false);
    const [paises, setPaises] = useState([{ key: null }]);
    const [valoresCuentas, setValoresCuentas] = useState({});
    const [cuentas, setCuentas] = useState([]);

    useEffect(() => {
        const llamada = async () => {
            const usuario = await axios.get(url_usuario, { headers });
            if (usuario) {
                setUsuario(usuario.data);
                ref_usuario.current?.resetFields();
            }
            const logo = await axios.get(url_logo, { headers }).catch(e => setFileList([]));
            if (logo && logo.data && logo.data.length) {
                setFileList([{
                    uid: '-1',
                    name: logo.data,
                    status: 'done',
                    url: `${process.env.REACT_APP_RUTA_API}/${logo.data}`
                }])
            }
            const cargar_paises = [];
            let valores_iniciales = {};
            let valores_cuentas = [];
            const cuenta = await axios.get(`${process.env.REACT_APP_RUTA_API}/cuentas?$limit=-1`, { headers });
            if (cuenta.data && cuenta.data.length) {
                valores_cuentas = cuenta.data;
                setCuentas(cuenta.data);
            }
            const pais = await axios.get(`${process.env.REACT_APP_RUTA_API}/paises?$limit=-1`, { headers });
            if (pais.data && pais.data.length) {
                for (const item of pais.data) {
                    item.key = item.id;
                    item.numero = (
                        <Form.Item
                            name={'n_' + item.key}
                            rules={[{ required: true, message: '*Campo requerido' }]}
                        >
                            <Input style={{ width: '200px', height: '25px' }} autoComplete="off" />
                        </Form.Item>
                    )
                    item.nombre_cuenta = (
                        <Form.Item
                            name={'c_' + item.key}
                            rules={[{ required: true, message: '*Campo requerido' }]}
                        >
                            <Input style={{ width: '200px', height: '25px' }} autoComplete="off" />
                        </Form.Item>
                    )
                    cargar_paises.push(item)
                    if (valores_cuentas.length) {
                        let valor = valores_cuentas.find(n => n.pais_id === item.key);
                        if (!valor) {
                            const enviar = {
                                numero: item.key,
                                nombre: item.nombre,
                                usuario_id: usuario.id,
                                pais_id: item.key
                            }
                            const respuesta = await axios.post(`${process.env.REACT_APP_RUTA_API}/cuentas`, { ...enviar }, { headers });
                            if (respuesta && respuesta.data) {
                                valor = respuesta.data;
                            }
                        }
                        valores_iniciales['n_' + item.key] = valor.numero;
                        valores_iniciales['c_' + item.key] = valor.nombre;
                    }

                }
                setPaises(cargar_paises);
                setValoresCuentas(valores_iniciales);
                ref_paises.current?.resetFields();
            }
        };
        llamada();
    }, []);

    const onFinish = async ({ subir, ...campos }) => {

        const respuesta = await axios.patch(`${process.env.REACT_APP_RUTA_API}/usuarios/${usuario.id}`, { ...campos }, { headers }).catch(controlErrores);
        if (respuesta && respuesta.data) {
            message.success(`"${respuesta.data.nombre}" se ha modificado con éxito.`);
        }
    };


    const props = {
        name: 'file',
        maxCount: 1,
        listType: "picture-card",
        action: url_logo,
        showUploadList: { showPreviewIcon: false },
        fileList,
        headers,
        async onChange(info) {
            if (info.fileList && info.fileList.length) {
                setFileList([info.fileList[0]])
            } else {
                setFileList([])
            }
            if (info.file.error) {
                message.error(`Error al subir el archivo.`);
                setCargando(false)
                return;
            }
            if (info.file.response) {
                info.file.name = info.file.response;

            }
            if (info.file.status === 'uploading') {
                setCargando(true)
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} El archivo se ha subido correctamente`);
                await axios.patch(`${process.env.REACT_APP_RUTA_API}/usuarios/${usuario.id}`, { logo: info.fileList[0].name }, { headers });
                setCargando(false)
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} Error al subir el archivo.`);
            } else if (info.file.status === "removed") {
                const comprobar = await axios.get(`${url_logo}&name=${info.file.name}`, { headers });
                if (comprobar) {
                    const borrar = await axios.delete(`${url_logo}&name=${info.file.name}`, { headers });
                    if (borrar) {
                        await axios.patch(`${process.env.REACT_APP_RUTA_API}/usuarios/${usuario.id}`, { logo: null }, { headers });
                        message.success(`${info.file.name} El archivo se ha borrado del servidor.`);
                    }
                }
            }
        },
    };

    const generarPlan = async (datos) => {
        let i = 0;
        let valido_crear = false;
        let valido_actualizar = false;
        let actualizar_cuentas = [];
        setCargandoPaises(true);
        const valores = Object.values(datos)
        for (const item of paises) {
            const enviar = {
                numero: valores[i * 2],
                nombre: valores[(i * 2) + 1],
                usuario_id: usuario.id,
                pais_id: item.id
            }
            if (cuentas.length === 0) {
                const respuesta = await axios.post(`${process.env.REACT_APP_RUTA_API}/cuentas`, { ...enviar }, { headers });
                if (respuesta && respuesta.data) {
                    actualizar_cuentas.push(respuesta.data)
                    if (i === (paises.length - 1)) {
                        valido_crear = true;
                    }
                }
            } else {
                const respuesta = await axios.patch(`${process.env.REACT_APP_RUTA_API}/cuentas/${cuentas[i].id}`, { ...enviar }, { headers });
                if (respuesta && respuesta.data) {
                    if (i === (paises.length - 1)) {
                        valido_actualizar = true;
                    }
                }
            }
            i++;
        }
        setCargandoPaises(false);
        if (valido_crear) {
            message.success(` Se ha creado el archivo de datos de cuenta`);
            setCuentas(actualizar_cuentas);
        } else if (valido_actualizar) {
            message.success(` Se ha modificado el archivo de datos de cuenta`);
        } else {
            message.error(`Error en la grabación de datos`);
        }
    };

    const controlErrores = (e) => {
        message.error(`${e.response.data.message}. Cambia el nombre del lote`);
    }

    return (
        <Row className="px-5 pt-5" gutter={8}>
            <Col span={10}>
                <Card title="Configurar Usuario" >
                    <Form
                        name="configurar_usuario"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        onFinish={onFinish}
                        initialValues={{
                            ...usuario
                        }}
                        ref={ref_usuario}
                        autoComplete="off"

                    >
                        <Form.Item
                            label="Nombre"
                            name="nombre"
                            rules={[{ required: true, message: '¡Introduzca denominación fiscal!' }]}
                            wrapperCol={{ span: 12 }}

                        >
                            <Input style={{ width: '200px' }} />
                        </Form.Item>

                        <Form.Item
                            label="CIF/DNI"
                            name="cif"
                            rules={[{ required: true, message: '¡Introduzca denominación fiscal!' }]}
                            wrapperCol={{ span: 12 }}

                        >
                            <Input style={{ width: '200px' }} />
                        </Form.Item>

                        <Form.Item
                            label="Dirección"
                            name="nombre_calle"
                            rules={[{ required: true, message: '¡Introduzca el nombre de la calle!' }]}
                            wrapperCol={{ span: 12 }}
                        >
                            <Input style={{ width: '200px' }} />
                        </Form.Item>

                        <Form.Item
                            label="Código Postal"
                            name="codigo_postal"
                            rules={[{ required: true, message: '¡Introduzca el código!' }]}
                            wrapperCol={{ span: 12 }}
                        >
                            <Input style={{ width: '200px' }} />
                        </Form.Item>

                        <Form.Item
                            label="Ciudad"
                            name="ciudad"
                            rules={[{ required: true, message: '¡Introduzca una ciudad!' }]}
                            wrapperCol={{ span: 12 }}
                        >
                            <Input style={{ width: '200px' }} />
                        </Form.Item>

                        <Form.Item
                            label="Provincia"
                            name="provincia"
                            rules={[{ required: true, message: '¡Introduzca una provincia!' }]}
                            wrapperCol={{ span: 12 }}
                        >
                            <Input style={{ width: '200px' }} />
                        </Form.Item>

                        <Form.Item
                            label="Pais"
                            name="pais"
                            rules={[{ required: true, message: '¡Introduzca el pais!' }]}
                            wrapperCol={{ span: 12 }}
                        >
                            <Input style={{ width: '200px' }} />
                        </Form.Item>

                        <Form.Item
                            label="Telefono"
                            name="telefono"
                            type="tel"
                            rules={[{ required: true, message: '¡Introduzca el telefono!' }]}
                            wrapperCol={{ span: 12 }}
                        >
                            <Input style={{ width: '200px' }} />
                        </Form.Item>


                        <Form.Item
                            name="subir"
                            label="Logo"
                        >
                            <Upload {...props}>
                                <div>
                                    {cargando ? <LoadingOutlined /> : <PlusOutlined />}
                                    <div style={{ marginTop: 8 }}>Subir</div>
                                </div>
                            </Upload>
                        </Form.Item>

                        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                            <Button type="primary" htmlType="submit">
                                Grabar Usuario
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>

            <Col span={14}>
                <Spin tip="Subiendo..." spinning={cargandoPaises}>
                    <Card title="Configurar Plan de Cuentas">
                        <Form
                            name="generar_plan"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 14 }}
                            onFinish={generarPlan}
                            initialValues={{ ...valoresCuentas }}
                            ref={ref_paises}
                        >
                            <Table className="contabilidad" columns={columnas} size="small" pagination={false} dataSource={paises} />

                            <Form.Item wrapperCol={{ span: 12, offset: 16 }} className="pt-4">
                                <Button type="primary" htmlType="submit">
                                    Grabar Plan de Cuentas
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Spin>
            </Col>

        </Row>

    )
}
