import { Row, Col, Button, Table, Card, message, Popconfirm, Form, InputNumber, DatePicker, Modal, Select } from 'antd'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';

const token = localStorage.getItem("accessToken")

const headers = {
    Authorization: `Bearer ${token}`
}

const columnas = [
    {
        title: 'Fecha movimiento',
        dataIndex: 'fecha_movimiento',
        key: 'fecha_movimiento',
        width: '30%',
        render: text => new Date(text).toLocaleDateString('es-ES')
    },
    {
        title: 'Producto',
        dataIndex: 'producto',
        key: 'producto',
        width: '40%',
        render: text => text?.nombre_propio
    },
    {
        title: 'Cantidad de Kg',
        dataIndex: 'total_kg',
        key: 'total_kg',
        width: '20%',
        align: 'right'
    },
    {
        title: 'Acciones',
        dataIndex: 'acciones',
        key: 'acciones',
        width: '10%'
    },
];

const ref_crear = React.createRef();

export const Gesdestrio = () => {

    const [actualizar, setActualizar] = useState([]);
    const [destrio, setDestrio] = useState([]);
    const [productos, setProductos] = useState([]);
    const [modalCrear, setModalCrear] = useState(false);

    const actualizador = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/destrio?$sort[fecha_movimiento]=-1&$limit=-1`, { headers });
        setActualizar(data);
    }

    const actualizadorProductos = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/productos?$sort[nombre_propio]=-1&$limit=-1`, { headers });
        setProductos(data?.map(item => { return { label: `${item.nombre_propio}`, value: item.id } }));
    }

    useEffect(() => {
        actualizador();
        actualizadorProductos();
    }, []);

    useEffect(() => {
        let isMounted = true;

        const cargarDestrios = [];
        if (actualizar) {
            for (const item of actualizar) {
                item.key = item.id;
                item.acciones = (
                    <Col className="text-right">
                        <Popconfirm placement="topLeft" title={'Esta operación no puede deshacerse, ¿quieres continuar?'} onConfirm={() => borrarDestrio(item.id)} okText="Aceptar" cancelText="Cancelar" icon="" className="bg-danger border-0">
                            <Button className="bg-danger border-0" type="primary" icon={<DeleteOutlined />} size={'small'} />
                        </Popconfirm>

                    </Col>
                )
                cargarDestrios.push(item)
            }
            if (isMounted) setDestrio(cargarDestrios);
        }
        return () => { isMounted = false }
    }, [actualizar]);

    const abrirModal = () => {
        setModalCrear(true)
    }

    const cerrarModalCrear = () => {
        setModalCrear(false);
        ref_crear.current?.resetFields();
        actualizador();
    }

    const borrarDestrio = async (destrio) => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_RUTA_API}/destrio/${destrio}`, { headers })
            actualizador();
            if (data) {
                message.success(`El destrío se ha borrado con éxito`)
            }
        } catch (e) {
            message.error(`Error al borrar el destrío`)
        }
    }

    const crearDestrio = async (datos) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_RUTA_API}/destrio`, { ...datos }, { headers })
            if (data) {
                ref_crear.current?.resetFields();
                setModalCrear(false);
                actualizador();
                message.success(`El destrío se ha creado con éxito`)
            }
        } catch (e) {
            message.error(`Error al crear el destrío`)
        }
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <>
            <Row className="px-5 pt-5" >
                <Col span={24}>
                    <Card
                        title="Gestionar Destrio"
                        extra={<Button type="primary" htmlType="submit" onClick={abrirModal}>
                            Crear Destrio
                        </Button>}
                    >
                        <Table className="destrio" columns={columnas} size="small" pagination={false} dataSource={destrio} />
                    </Card>
                </Col>
            </Row>
            <Modal title="Crear Destrio" visible={modalCrear} onCancel={cerrarModalCrear} footer={null}>
                <Form initialValues={{ numero_actual: 1 }} name="crear" ref={ref_crear} onFinish={crearDestrio}>
                    <Form.Item name="fecha_movimiento" label="Fecha movimiento" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <DatePicker />
                    </Form.Item>
                    <Form.Item name="producto_id" label="Producto" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Select
                            showSearch
                            placeholder="Selecciona un producto"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={productos}
                        />
                    </Form.Item>
                    <Form.Item name="total_kg" label="Total Kg" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <InputNumber
                            parser={value => value.replace(new RegExp(/,/g), '.')} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 20, span: 24 }} >
                        <Button type="primary" htmlType="submit">
                            Crear
                        </Button>
                    </Form.Item>
                </Form>
            </Modal >
        </>

    )

}
