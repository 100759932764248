import axios from 'axios';
import esES from 'antd/es/date-picker/locale/es_ES';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  InputNumber,
  Button,
  Upload,
  Input,
  Card,
  message,
  DatePicker,
  Spin,
  Col,
  Select
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;

const url_uploads = `${process.env.REACT_APP_RUTA_API}/archivos?carpeta=uploads`;

const headers = { Authorization: `Bearer ${localStorage.getItem("accessToken")}` };

const ref_formulario = React.createRef();

export const Lotes = ({ setLotes }) => {

  const [cargando, setCargando] = useState(false);
  const [contadores, setContadores] = useState([]);
  const [contador, setContador] = useState(false);

  const props = {
    name: 'file',
    maxCount: 1,
    action: url_uploads,
    headers,
    async onChange(info) {
      if (info.file.error) {
        message.error(`Error al subir el archivo.`);
        return;
      }
      if (info.file.response) {
        info.file.name = info.file.response;
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} El archivo se ha subido correctamente`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} Error al subir el archivo.`);
      } else if (info.file.status === "removed") {
        const comprobar = await axios.get(`${url_uploads}&name=${info.file.name}`, { headers });
        if (comprobar) {
          const borrar = await axios.delete(`${url_uploads}&name=${info.file.name}`, { headers });
          if (borrar) {
            message.success(`${info.file.name} El archivo se ha borrado del servidor.`);
          }
        }
      }
    },
  };

  const actualizador = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/contadores?$limit=-1`, { headers });
    setContadores(data);
  }
  useEffect(() => {
    actualizador()
  }, []);

  const onFinish = async ({ nombre, contador, numero, fecha, subir }) => {
    if (subir && subir.file.status === 'done') {
      setCargando(true)
      try {
        const respuesta = await axios.post(`${process.env.REACT_APP_RUTA_API}/lotes`, { nombre, contador, numero, fecha: fecha.format('YYYY-MM-DD') }, { headers });
        if (respuesta && respuesta.data) {
          message.success(` El lote "${respuesta.data.nombre}" se ha creado con éxito.`);
          setLotes(respuesta.data)
        }
      } catch (error) {
        message.error(`Se ha producido un error interno del servidor y no se pudo crear el lote, por favor, contacte con el administrador: ${error.response.data.message}`, 4);
      }

      setCargando(false);
    } else {
      message.error(`Sube un archivo`);
    }
  };


  return (
    <Spin tip="Subiendo..." spinning={cargando}>
      <Col span={16} offset={4} className="py-5">
        <Card title="Cargar Lote" >
          <Form
            ref={ref_formulario}
            name="cargar_lote"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            onFinish={onFinish}
            initialValues={{ 'numero': 1 }}
          >
            <Form.Item
              label="Nombre del lote"
              name="nombre"
              rules={[{ required: true, message: '¡Introduzca un nombre!' }]}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ width: '200px' }} autoComplete="off" />
            </Form.Item>

            <Form.Item name="fecha" label="Fecha Facturas" rules={[{ type: 'object', required: true, message: 'Selecciona una fecha de factura!' }]}>
              <DatePicker style={{ width: '200px' }} placeholder="Selecciona fecha..." locale={esES} />
            </Form.Item>


            <Form.Item name="contador" label="Contador">
              <Select style={{ width: '200px' }} onChange={(value) => { setContador(value) }}
                placeholder="Indicar si se quiere usar un contador existente..."
                allowClear
              >
                {
                  contadores && contadores.map(item => <Option value={item.id} key={item.id}>{item.prefijo}{item.pais_prefijo == 0 ? '' : '[PAIS]'}{item.pais_sufijo == 0 ? '' : '[PAIS]'}{item.sufijo}</Option>)
                }
              </Select>
            </Form.Item>

            {!contador &&
              <Form.Item label="Número Primera Factura" >
                <Form.Item
                  name="numero"
                  noStyle
                >
                  <InputNumber min={1} style={{ width: '200px' }} />
                </Form.Item>
              </Form.Item>
            }

            <Form.Item
              name="subir"
              label="Archivo XLXS"
              valuePropName='Filelist'
            >
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>Click para subir archivo</Button>
              </Upload>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
              <Button type="primary" htmlType="submit">
                Cargar Lote
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Spin>

  )

}

Lotes.propTypes = {
  setLotes: PropTypes.func.isRequired
}
