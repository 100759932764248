import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useLocation, useNavigate, generatePath } from 'react-router-dom';
import { Login } from './paginas/login/Login';
import { Configuracion } from './paginas/configuracion/Configuracion';
import { Lotes } from './paginas/lotes/Lotes';
import { Numeros } from './paginas/numeros/Numeros';
import { Contabilidad } from './paginas/contabilidad/Contabilidad';
import { Imprimir } from './paginas/imprimir/Imprimir';
import { Usuarios } from './paginas/usuarios/Usuarios';
import { Paises } from './paginas/paises/Paises';
import { Contadores } from './paginas/contadores/Contadores';
import { Pagina404 } from './paginas/Pagina404/Pagina404';
import axios from 'axios';
import {
    Layout,
    Menu,
    Button,
    Popconfirm,
    Form,
    Select,
    ConfigProvider,
    theme
} from 'antd';

import {
    DownloadOutlined,
    AreaChartOutlined,
    PrinterOutlined,
    FieldNumberOutlined,
    UploadOutlined,
    ContainerOutlined,
    SettingOutlined,
    PoweroffOutlined,
    UserOutlined,
    GlobalOutlined,
    UnorderedListOutlined,
    BorderOuterOutlined,
    CalendarOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import './app.less';
import { jwtDecode as jwt_decode } from 'jwt-decode';
import { Geslotes } from './paginas/geslotes/Geslotes';
import { Gessalidas } from './paginas/gessalidas/Gessalidas';
import { Estadisticas } from './paginas/estadisticas/Estadisticas';
import { Gesproductos } from './paginas/gesproductos/Gesproductos';
import { Gesdestrio } from './paginas/Gesdestrio/Gesdestrio';
import { Gestemporadas } from './paginas/Gestemporadas/Gestemporadas';
import { Hojacmr } from './paginas/hojacmr/Hojacmr';
import { Hojamercancia } from './paginas/hojamercancia/Hojamercancia';



const { Header, Content, Sider } = Layout;

const { Option } = Select;

const token = localStorage.getItem("accessToken");

const headers = {
    Authorization: `Bearer ${token}`
}

let jwt;

if (token) {
    try {
        jwt = jwt_decode(token);
    } catch (error) {
    }
}

const capturarError = (codigo, error) => {
    if (codigo === 401) {
        localStorage.removeItem("accessToken");
        window && window.location.reload();
    } else if (codigo === 400) {
        throw error;
    } else if (codigo === 500) {
        throw error;
    }
};


axios.interceptors.response.use(response => {
    if (response.response && response.response.data) {
        capturarError(response.response.data.code, response.response.data.message);
    }
    return response;
}, error => {

    if (error.response?.status === 401 && localStorage.getItem("accessToken")) {
        capturarError(error.response?.status, '');
    } else {
        capturarError(error.response?.status, error);
    }
    return error;
});

export const App = () => {

    const location = useLocation();

    const [state, setState] = useState({ collapsed: false });

    const [navegacion, setNavegacion] = useState();

    const [lotes, setLotes] = useState([]);

    const [loteSeleccionado, setLoteSeleccionado] = useState();

    const [imprimir, setImprimir] = useState(false);

    const [usuario, setUsuario] = useState({});

    useEffect(() => {
        switch (location.pathname) {
            case '/': setNavegacion('1'); break;
            case '/lotes': setNavegacion('2'); break;
            case '/numeros': setNavegacion('3'); break;
            case '/contabilidad': setNavegacion('4'); break;
            case '/imprimir/:lote': setNavegacion('5'); break;
            case '/usuarios': setNavegacion('6'); break;
            case '/paises': setNavegacion('7'); break;
            case '/contadores': setNavegacion('8'); break;
            case '/geslotes': setNavegacion('9'); break;
            case '/salidas': setNavegacion('10'); break;
            case '/productos': setNavegacion('12'); break;
            case '/destrio': setNavegacion('13'); break;
            case '/temporadas': setNavegacion('14'); break;
            case '/estadisticas': setNavegacion('11'); break;
            case '/hojacmr': setNavegacion('15'); break;
            default: {
                const ruta = location.pathname.split('/');
                if (ruta[1] === 'imprimir' && ruta[2] !== '') {
                    setNavegacion('5');
                    setImprimir(true);
                } else {
                    setNavegacion('0');
                }
            } break;
        }
    }, [location])

    useEffect(() => {
        const llamada = async () => {
            if (!token) return;
            const cargarLote = [];
            const l = await axios.get(`${process.env.REACT_APP_RUTA_API}/lotes?$limit=-1`, { headers });
            if (l.data && l.data.length) {
                for (const item of l.data) {
                    cargarLote.push(item)
                }
                setLotes(cargarLote);

            }

            const u = await axios.get(`${process.env.REACT_APP_RUTA_API}/usuarios/${jwt?.usuario?.id}`, { headers });
            if (u.data) {
                setUsuario(u.data);

            }

        };
        llamada();
    }, []);

    const actualizarLotes = (lote) => {
        setLotes([...lotes, lote]);

    }

    const ordenar = (a, b) => {
        if (a.id < b.id) {
            return 1;
        }
        if (a.id > b.id) {
            return -1;
        }
        return 0;
    }

    const onCollapse = collapsed => {
        setState({ collapsed });
    };

    const { collapsed } = state;

    const accessToken = localStorage.getItem("accessToken");

    const logout = () => {
        localStorage.removeItem("accessToken")
        window.location.href = "/login";
    }

    const seleccionado = (lote) => {
        setLoteSeleccionado(lote);
    }

    const navigate = useNavigate();
    const confirm = () => {

        if (loteSeleccionado) {
            setImprimir(true);
            const path = generatePath("/imprimir/:lote", {
                lote: loteSeleccionado
            });
            navigate(path, { replace: true });
        }
    }

    const seleccion = (
        <Form
            name="seleccion_lote"
        >
            <Form.Item
                name="lote"
                wrapperCol={{ span: 12 }}
            >
                <Select placeholder="Selecciona un lote" style={{ width: '200px' }} onSelect={seleccionado}>
                    {
                        lotes && lotes.sort(ordenar).map(item => <Option value={item.id} key={item.id}>{item.nombre}</Option>)
                    }
                </Select>
            </Form.Item>
        </Form>
    );

    return (
        <ConfigProvider
            theme={{
                algorithm: theme.defaultAlgorithm,
                token: {
                    colorPrimary: '#1DA57A'
                },
            }}
        >
            {(!accessToken || accessToken === null) && <Login />}
            {accessToken && accessToken !== null &&
                <Layout style={{ minHeight: '100vh' }} >
                    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} className="noimprimir">
                        <div className="logo d-flex justify-content-start align-items-center">
                            <ContainerOutlined className="mr-2 icono_logo" />
                            <h3>
                                factuLotes
                                <small className="ml-2 text-muted">BETA</small>
                            </h3>
                        </div>
                        <Menu theme="dark" selectedKeys={[navegacion]} mode="inline" >
                            <Menu.Item key="1" icon={<SettingOutlined />}>
                                <Link to="/">Configurar Usuario</Link>
                            </Menu.Item>
                            <Menu.Item key="2" icon={<UploadOutlined />}>
                                <Link to="/lotes">Cargar Lote</Link>
                            </Menu.Item>
                            <Menu.Item key="9" icon={<UnorderedListOutlined />}>
                                <Link to="/geslotes">Gestionar Lotes</Link>
                            </Menu.Item>
                            <Menu.Item key="10" icon={<UnorderedListOutlined />}>
                                <Link to="/salidas">Gestionar Salidas</Link>
                            </Menu.Item>
                            <Menu.Item key="12" icon={<BorderOuterOutlined />}>
                                <Link to="/productos">Gestionar Productos</Link>
                            </Menu.Item>
                            <Menu.Item key="13" icon={<DeleteOutlined />}>
                                <Link to="/destrio">Gestionar Destrío</Link>
                            </Menu.Item>
                            <Menu.Item key="14" icon={<CalendarOutlined />}>
                                <Link to="/temporadas">Gestionar Campañas</Link>
                            </Menu.Item>
                            <Menu.Item key="3" icon={<FieldNumberOutlined />}>
                                <Link to="/numeros">Cambiar Numeración</Link>
                            </Menu.Item>
                            <Menu.Item key="8" icon={<FieldNumberOutlined />}>
                                <Link to="/contadores">Gestionar contadores</Link>
                            </Menu.Item>
                            <Menu.Item key="4" icon={<DownloadOutlined />}>
                                <Link to="/contabilidad">Fichero Contabilidad</Link>
                            </Menu.Item>
                            <Menu.Item key="11" icon={<AreaChartOutlined />}>
                                <Link to="/estadisticas">Estadísticas</Link>
                            </Menu.Item>
                            <Menu.Item key="5" icon={<PrinterOutlined />}>
                                <Popconfirm placement="topLeft" title={seleccion} onConfirm={confirm} okText="Aceptar" cancelText="Cancelar" icon="">
                                    Imprimir Facturas
                                </Popconfirm>
                            </Menu.Item>
                            {
                                usuario.rol === 'admin' &&
                                <Menu.Item key="6" icon={<UserOutlined />}>
                                    <Link to="/usuarios">Gestionar Usuarios</Link>
                                </Menu.Item>
                            }
                            {
                                usuario.rol === 'admin' &&
                                <Menu.Item key="7" icon={<GlobalOutlined />}>
                                    <Link to="/paises">Gestionar Paises</Link>
                                </Menu.Item>
                            }
                        </Menu>
                    </Sider>
                    <Layout>
                        <Header style={{ padding: 0 }} className="noimprimir">
                            <div className="text-right pr-3">
                                <Button type="primary" shape="circle" size="large" onClick={logout}>
                                    <PoweroffOutlined />
                                </Button>
                            </div>
                        </Header>
                        <Content className="ajustar">
                            <Routes>
                                <Route exact path="/" element={<Configuracion />} />
                                <Route exact path="/lotes" element={<Lotes setLotes={actualizarLotes} />} />
                                <Route exact path="/geslotes" element={<Geslotes />} />
                                <Route exact path="/salidas" element={<Gessalidas />} />
                                <Route exact path="/productos" element={<Gesproductos />} />
                                <Route exact path="/destrio" element={<Gesdestrio />} />
                                <Route exact path="/temporadas" element={<Gestemporadas />} />
                                <Route exact path="/contadores" element={<Contadores />} />
                                <Route exact path="/numeros" element={<Numeros />} />
                                <Route exact path="/contabilidad" element={<Contabilidad />} />
                                <Route exact path="/estadisticas" element={<Estadisticas />} />
                                <Route exact path="/hojacmr" element={<Hojacmr />} />
                                <Route exact path="/hojamercancia/:fecha" element={<Hojamercancia />} />
                                {
                                    imprimir && <Route exact path="/imprimir/:lote" element={<Imprimir />} />
                                }
                                {
                                    usuario.rol === 'admin' && <Route exact path="/usuarios" element={<Usuarios />} />
                                }
                                {
                                    usuario.rol === 'admin' && <Route exact path="/paises" element={<Paises />} />
                                }
                                <Route path='*' element={<Pagina404 />} />
                            </Routes>
                        </Content>
                    </Layout>
                </Layout>
            }
        </ConfigProvider>
    );
}
