import { Row, Col, Button, Table, Card, message, Popconfirm, Form, Input, DatePicker, Modal } from 'antd'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';

const token = localStorage.getItem("accessToken")

const headers = {
    Authorization: `Bearer ${token}`
}

const columnas = [
    {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre',
        width: '40%',
    },
    {
        title: 'Fecha inicio',
        dataIndex: 'fecha_inicio',
        key: 'fecha_inicio',
        width: '25%',
        render: text => new Date(text).toLocaleDateString('es-ES')
    },
    {
        title: 'Fecha fin',
        dataIndex: 'fecha_fin',
        key: 'fecha_fin',
        width: '25%',
        render: text => new Date(text).toLocaleDateString('es-ES')
    },
    {
        title: 'Acciones',
        dataIndex: 'acciones',
        key: 'acciones',
        width: '10%'
    },
];

const ref_crear = React.createRef();

export const Gestemporadas = () => {

    const [actualizar, setActualizar] = useState([]);
    const [temporadas, setTemporadas] = useState([]);
    const [modalCrear, setModalCrear] = useState(false);

    const actualizador = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/temporadas?$sort[fecha_inicio]=-1&$limit=-1`, { headers });
        setActualizar(data);
    }

    useEffect(() => {
        actualizador();
    }, []);

    useEffect(() => {
        let isMounted = true;

        const cargarSalidas = [];
        if (actualizar) {
            for (const item of actualizar) {
                item.key = item.id;
                item.acciones = (
                    <Col className="text-right">
                        <Popconfirm placement="topLeft" title={'Esta operación no puede deshacerse, ¿quieres continuar?'} onConfirm={() => borrarTemporada(item.id)} okText="Aceptar" cancelText="Cancelar" icon="" className="bg-danger border-0">
                            <Button className="bg-danger border-0" type="primary" icon={<DeleteOutlined />} size={'small'} />
                        </Popconfirm>

                    </Col>
                )
                cargarSalidas.push(item)
            }
            if (isMounted) setTemporadas(cargarSalidas);
        }
        return () => { isMounted = false }
    }, [actualizar]);

    const abrirModal = () => {
        setModalCrear(true)
    }

    const cerrarModalCrear = () => {
        setModalCrear(false);
        ref_crear.current?.resetFields();
        actualizador();
    }

    const borrarTemporada = async (temporada) => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_RUTA_API}/temporadas/${temporada}`, { headers })
            actualizador();
            if (data) {
                message.success(`La campaña se ha borrado con éxito`)
            }
        } catch (e) {
            message.error(`Error al borrar la campaña`)
        }
    }

    const crearTemporada = async (datos) => {
        console.log(datos)
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_RUTA_API}/temporadas`, { ...datos }, { headers })
            if (data) {
                ref_crear.current?.resetFields();
                setModalCrear(false);
                actualizador();
                message.success(`La campaña se ha creado con éxito`)
            }
        } catch (e) {
            message.error(`Error al crear la campaña`)
        }
    }

    return (
        <>
            <Row className="px-5 pt-5" >
                <Col span={24}>
                    <Card
                        title="Gestionar Campañas"
                        extra={<Button type="primary" htmlType="submit" onClick={abrirModal}>
                            Crear Campaña
                        </Button>}
                    >
                        <Table className="temporadas" columns={columnas} size="small" pagination={false} dataSource={temporadas} />
                    </Card>
                </Col>
            </Row>
            <Modal title="Crear Campaña" visible={modalCrear} onCancel={cerrarModalCrear} footer={null}>
                <Form initialValues={{ numero_actual: 1 }} name="crear" ref={ref_crear} onFinish={crearTemporada}>
                    <Form.Item name="nombre" label="Nombre de la campaña" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="fecha_inicio" label="Fecha inicio" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <DatePicker />
                    </Form.Item>
                    <Form.Item name="fecha_fin" label="Fecha fin" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <DatePicker />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 20, span: 24 }} >
                        <Button type="primary" htmlType="submit">
                            Crear
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )

}
